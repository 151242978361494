/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../EKAMPUS/pages/login/loginEkampus.component.ngfactory";
import * as i3 from "../../EKAMPUS/pages/login/loginEkampus.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/platform-browser";
import * as i6 from "@angular/router";
import * as i7 from "@angular/forms";
import * as i8 from "../../services/login.service";
import * as i9 from "../../services/config.service";
import * as i10 from "ngx-spinner";
import * as i11 from "@angular/material/dialog";
import * as i12 from "../../IP/pages/login/loginIP.component.ngfactory";
import * as i13 from "../../IP/pages/login/loginIP.component";
import * as i14 from "@angular/common";
import * as i15 from "./login.component";
var styles_LoginComponent = [i0.styles];
var RenderType_LoginComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginComponent, data: {} });
export { RenderType_LoginComponent as RenderType_LoginComponent };
function View_LoginComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-loginEkampus", [], null, null, null, i2.View_LoginEkampusComponent_0, i2.RenderType_LoginEkampusComponent)), i1.ɵdid(2, 114688, null, 0, i3.LoginEkampusComponent, [i4.TranslateService, i5.Title, i6.ActivatedRoute, i6.Router, i6.ActivatedRoute, i7.FormBuilder, i8.LoginService, i9.ConfigService, i10.NgxSpinnerService, i11.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_LoginComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-loginIP", [], null, null, null, i12.View_LoginIPComponent_0, i12.RenderType_LoginIPComponent)), i1.ɵdid(2, 114688, null, 0, i13.LoginIPComponent, [i4.TranslateService, i5.Title, i6.ActivatedRoute, i6.Router, i6.ActivatedRoute, i7.FormBuilder, i8.LoginService, i9.ConfigService, i10.NgxSpinnerService, i11.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_LoginComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginComponent_1)), i1.ɵdid(1, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginComponent_2)), i1.ɵdid(3, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.entityType == "EKAMPUS"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.entityType == "IP"); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_LoginComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login", [], null, null, null, View_LoginComponent_0, RenderType_LoginComponent)), i1.ɵdid(1, 114688, null, 0, i15.LoginComponent, [i9.ConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginComponentNgFactory = i1.ɵccf("app-login", i15.LoginComponent, View_LoginComponent_Host_0, {}, {}, []);
export { LoginComponentNgFactory as LoginComponentNgFactory };
