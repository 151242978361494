import { Component, OnInit,Input, Output, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-account-selector-popup',
  templateUrl: './account-selector-popup.component.html',
  styleUrls: ['./account-selector-popup.component.css']
})
export class AccountSelectorPopupComponent {
  @Input() showPopup: boolean = false;
  @Input() accounts: any[] = []; // Liste des comptes

  constructor(
    public dialogRef: MatDialogRef<AccountSelectorPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  // Méthode pour sélectionner un compte
  onSelectAccount(account: any): void {
    this.dialogRef.close(account);  // Retourne le compte sélectionné
  }

}
