/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sso-connection.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/ngx-spinner/ngx-spinner.ngfactory";
import * as i3 from "ngx-spinner";
import * as i4 from "@angular/common";
import * as i5 from "./sso-connection.component";
import * as i6 from "@ngx-translate/core";
import * as i7 from "@angular/platform-browser";
import * as i8 from "@angular/router";
import * as i9 from "../../services/login.service";
import * as i10 from "../../services/user.service";
import * as i11 from "../../services/config.service";
import * as i12 from "@angular/material/dialog";
var styles_SsoConnectionComponent = [i0.styles];
var RenderType_SsoConnectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SsoConnectionComponent, data: {} });
export { RenderType_SsoConnectionComponent as RenderType_SsoConnectionComponent };
function View_SsoConnectionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "container banner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "jumbotron jumbotron-fluid"], ["style", "background-color: #1d81c1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [["class", "display-4"], ["style", "color:#FFFFFF"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Acc\u00E8s interdit !"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "lead"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Vous n'avez pas de droit \u00E0 ce contenu."])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [["class", "lead"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Pour plus d'information, veuillez contacter l'\u00E9quipe Kaptitude"]))], null, null); }
export function View_SsoConnectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ngx-spinner", [["bdColor", "rgba(51,51,51,0.8)"], ["color", "#fff"], ["size", "medium"], ["type", "ball-scale-multiple"]], null, null, null, i2.View_NgxSpinnerComponent_0, i2.RenderType_NgxSpinnerComponent)), i1.ɵdid(1, 770048, null, 0, i3.NgxSpinnerComponent, [i3.NgxSpinnerService, i1.ChangeDetectorRef], { bdColor: [0, "bdColor"], size: [1, "size"], color: [2, "color"], type: [3, "type"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "p", [["style", "font-size: 20px; color: white"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Connexion \u00E0 Certikap en cours..."])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SsoConnectionComponent_1)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "rgba(51,51,51,0.8)"; var currVal_1 = "medium"; var currVal_2 = "#fff"; var currVal_3 = "ball-scale-multiple"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.showForrbidden; _ck(_v, 5, 0, currVal_4); }, null); }
export function View_SsoConnectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sso-connection", [], null, null, null, View_SsoConnectionComponent_0, RenderType_SsoConnectionComponent)), i1.ɵdid(1, 114688, null, 0, i5.SsoConnectionComponent, [i6.TranslateService, i7.Title, i8.ActivatedRoute, i8.Router, i9.LoginService, i10.UserService, i3.NgxSpinnerService, i11.ConfigService, i12.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SsoConnectionComponentNgFactory = i1.ɵccf("app-sso-connection", i5.SsoConnectionComponent, View_SsoConnectionComponent_Host_0, {}, {}, []);
export { SsoConnectionComponentNgFactory as SsoConnectionComponentNgFactory };
