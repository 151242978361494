import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';
import {MatTreeModule} from '@angular/material/tree';
import { CertificationService } from 'src/app/services/certification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfigService} from '../../../services/config.service';
import { OrderPipe } from 'ngx-order-pipe';
import {ExportToCsv} from 'export-to-csv';
import {SiteService} from '../../../services/site.service';
import {Verification} from "../../../models/verification.model";
import {Site} from "../../../models/site.model";
import {VerificationSite} from "../../../models/verification-site.model";
import {Certificat} from "../../../models/certificat.model";
import {CertificatSite} from "../../../models/certificat-site.model";

@Component({
  selector: 'app-certification',
  templateUrl: './certification.component.html',
  styleUrls: ['./certification.component.css']
})
export class CertificationComponent implements OnInit {
  order = 'certificat.name';
  reverse = false;
  sortedCollection: any[];
  // tslint:disable-next-line:max-line-length
  constructor(private siteService: SiteService, private orderPipe: OrderPipe, private configService: ConfigService, private certificationService: CertificationService, private userService: UserService, private spinner: NgxSpinnerService, private el: ElementRef, private renderer: Renderer2, private router: Router, private route: ActivatedRoute) {
    this.sortedCollection = orderPipe.transform(this.certificats, 'certificat.name');
  }
  // tslint:disable-next-line:max-line-length
  certId: any;

  // tslint:disable-next-line:max-line-length variable-name
  count_sub_certifification: any;
  // tslint:disable-next-line:variable-name radix
  public profile_id = parseInt(localStorage.getItem('profile_id'));
  public certificats: any;
  // tslint:disable-next-line:variable-name
  public site_id: any;
  // tslint:disable-next-line:variable-name
  public list_sites: any;
  // tslint:disable-next-line:variable-name
  public count_certificat: any;
  show = 10;
  vQuery: string;
  addingSuccess: boolean;
// tslint:disable-next-line:variable-name
  list_certificat: any;
  // tslint:disable-next-line:variable-name
  public showLoader = true;
  public showLabel = true;
  // tslint:disable-next-line:variable-name
  public list_sub_cert_for_export;
  public entityType = this.configService.entityType;

  ngOnInit() {
    this.configService.checkUSerAccessByProfile(this.profile_id);
    this.get_list_certification();
    this.get_list_sites();
  }
  AllCertifications: Certificat[] = [];
  AllSites: Site[] = [];
  AllCertificationsSites: CertificatSite[] = [];

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }

  get_list_certification() {
    this.spinner.show();
    this.certificationService.getCertificat()
      .subscribe(res => {
        // @ts-ignore
        this.count_certificat = res.data.certificat.length;
        // @ts-ignore
        this.certificats = res.data.certificat;
        this.list_sub_cert_for_export = res.data.export_sous_certification;
        this.count_sub_certifification = res.data.sous_certification;
        this.AllCertifications = res.certifications_all;
        this.AllSites = res.sites_all;
        this.AllCertificationsSites = res.CertificationSite;
        this.spinner.hide();
      }, (err) => {
        console.log(err);
        this.spinner.hide();
      });
  }
  increaseShow() {
    this.spinner.show();
    this.show += 10;
    this.spinner.hide();
  }

  // tslint:disable-next-line:variable-name
  editer(id_certificat) {
    this.router.navigate(['/dashboard/administrator/certification/editer/' + id_certificat], { relativeTo: this.route });
  }

  supprimer(id_certificat) {
    Swal.fire({
      title: 'Etes-vous sûr(e) ?',
      text: 'Cette action est irréversible',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui supprimer',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.value) {
        const body = {
          id: id_certificat
        };
        // @ts-ignore
        this.certificationService.deleteCertificats(body)
          .subscribe(res => {
            this.addingSuccess = true;
            this.get_list_certification();
          }, (err) => {
            console.log(err);
          });
        Swal.fire(
          'Supprimé !',
          'Certificat supprimé avec succès !',
          'success'
        );
      }
    });
  }

  sites(id_certificat) {
    this.router.navigate(['/dashboard/administrator/certifications/site/' + id_certificat], { relativeTo: this.route });

  }
  verifications(id_certificat) {
    this.router.navigate(['/dashboard/administrator/certifications/verification/' + id_certificat], { relativeTo: this.route });

  }

    listingSousCertificat(id: any) {
      this.showLoader = true;
      this.showLabel = false;
      this.certificationService.getSousCertificatbyCertificat(id)
        .subscribe(res => {
          // @ts-ignore
          this.showLabel = true;
          if (res.data.length !== 0) {
            this.list_certificat = res.data;
            for (const subCert of this.list_certificat) {
              this.certId = subCert.certificat_id;
            }
          }
          this.showLoader = false;
        }, (err) => {
          console.log(err);
        });
  }

  exporter() {
    const dataToExport = [];
    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Exportation des certifications',
      filename: 'liste_certification_' + this.formatDate(),
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      // tslint:disable-next-line:max-line-length
      headers: ['ID', 'Libellé', 'Sous-certifications', 'Type de validité', 'Durée']
    };
    this.certificats.forEach((rdata, index) => {
      let labelSubCert = '';
      // tslint:disable-next-line:variable-name
      this.list_sub_cert_for_export.forEach((subData, Subindex) => {
        const fake = '';
        subData.forEach((labelSus, bindex) => {
          if (labelSus.certificat_id === rdata.id) {
            labelSubCert += labelSus.name + ' - ';
            }
        });
      });
      const  data = {
        nom: rdata.code_cert,
        label: rdata.name,
        sous_certifications: labelSubCert.slice(0, -2),
        validite: rdata.type_validite === 2 ? 'Mois' : 'Date',
        duree: rdata.type_validite === 2 ? rdata.delay : rdata.date,
      };
      dataToExport.push(data);
    });
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(dataToExport);
  }

  formatDate() {
    // tslint:disable-next-line:one-variable-per-declaration
    const d = new Date();
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    const hour = d.getHours();
    const minute = d.getMinutes();
    const seconde = d.getSeconds();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    const dateJoin = [year, month, day].join('');
    const hourJOin = [hour, minute, seconde].join('');
    return [dateJoin, hourJOin].join('_');
  }

  get_for_exportlistingSousCertificat(id) {
    this.spinner.show();
    this.certificationService.getSousCertificatbyCertificat(id)
      .subscribe(res => {
       this.list_sub_cert_for_export =  res.data;
       this.spinner.hide();
      }, (err) => {
        console.log(err);
      });
  }

  // tslint:disable-next-line:variable-name
  get_certification_sites(id_site) {
    this.spinner.show();
    this.certificationService.get_certification_sites_list(id_site)
      .subscribe(res => {
        // Éliminer les doublons
        const uniqueCertifications = res.data.filter((certification, index, self) =>
          index === self.findIndex((cert) => cert.id === certification.id)
        );
        // Mettre à jour le compteur et la liste avec les certifications uniques
        this.count_certificat = uniqueCertifications.length; 
        this.certificats = uniqueCertifications; 
        this.spinner.hide();
      }, (err) => {
        console.log(err);
        this.spinner.hide(); 
      });
  }

  get_list_sites() {
    this.siteService.getSite()
      .subscribe(res => {
        this.list_sites = res.data;
      }, (err) => {
        console.log(err);
      });
  }
  
  filterSite(value) {
    // Si l'utilisateur sélectionne "Tout" ou "Veuillez sélectionner le site", récupére toutes les certifications
    if (value === '7xxx7' || value === '788') {
      this.get_list_certification();
    } else {
      // Sinon, filtrer par site
      this.get_certification_sites(value);
    }
  }
}