import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginService} from '../../services/login.service';
import {UserService} from '../../services/user.service';
import {NgxSpinnerService} from 'ngx-spinner';
import Swal from "sweetalert2";
import {User} from "../../models/user.model";
import {BehaviorSubject, Observable} from "rxjs";
import {ConfigService} from "../../services/config.service";
import {AccountSelectorPopupComponent} from "../../dashboard/template/account-selector-popup/account-selector-popup.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-sso-connection',
  templateUrl: './sso-connection.component.html',
  styleUrls: ['./sso-connection.component.css']
})
export class SsoConnectionComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  public user_id: string;
  public user_token: string;
  showForrbidden: boolean;
  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  constructor(
    public translate: TranslateService,
    public pageTitle: Title,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private configService: ConfigService,
    private dialog: MatDialog
  ) {
    this.user_id = activatedRoute.snapshot.url[3].path;
    this.user_token = activatedRoute.snapshot.url[4].path;
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }
  public entityType = this.configService.entityType;

  ngOnInit() {
    setTimeout(() => {
      this.configService.redirectTools();
    }, 500); // délai de 2000 ms (2 secondes)
    setTimeout(() => {
      this.sso_certikap(this.user_id,this.user_token);
    }, 1000); // délai de 2000 ms (2 secondes)
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  sso_certikap(user_id,user_token) {
    this.spinner.show();
    const body = {
      user_id : user_id,
      user_token : user_token
    };
    this.loginService.sso_connexion_lms_to_certikap(body)
      .subscribe(res => {
        // @ts-ignore
        if(res.status === 'multicomptes'){
          this.spinner.hide();
          // @ts-ignore
          this.openMulticomptesDialog(res.multicomptes);
        }
        // @ts-ignore
        else if (res.status === 'success') {
          this.loginService.logout_take_in_hand();
          localStorage.removeItem('currentUser');
          localStorage.removeItem('tokenCurrentConnected');
          // @ts-ignore
          localStorage.setItem('currentUser', JSON.stringify(res));
          // @ts-ignore
          localStorage.setItem('tokenCurrentConnected', JSON.stringify(res.token));
          // @ts-ignore
          this.loginService.currentUserSubject.next(res);
          // @ts-ignore
          localStorage.setItem('profile_id', res.profile_id);
          // @ts-ignore
          localStorage.setItem('site_id', res.site_id);
          // @ts-ignore
          this.configService.redirectTools();
          window.location.reload();
          return res;

        } else {
          this.showForrbidden = true;
          this.spinner.hide();
        }
      }, (err) => {
        this.showForrbidden = true;
        this.spinner.hide();
      });
  }

  // Méthode pour ouvrir la pop-up
  openMulticomptesDialog(multicomptes: any[]) {
    const dialogRef = this.dialog.open(AccountSelectorPopupComponent, {
      width: '400px',
      data: { multicomptes: multicomptes }
    });

    dialogRef.afterClosed().subscribe(selectedCompte => {
      if (selectedCompte) {
        // Si un compte a été sélectionné, tu peux gérer la redirection ici
        this.switchAccount(selectedCompte);
      }
    });
  }

  switchAccount(User :any){
    this.spinner.show();
    this.loginService.switchAccount(User.login)
      .subscribe(res => {
        if (res.status == 'success') {
          this.spinner.hide();
          localStorage.setItem('profile_id', res.profile_id);
          localStorage.setItem('site_id', res.site_id);
          if (res.profile_id === 10 || res.profile_id === 8) {
            window.location.reload();
            this.router.navigate(['dashboard/inspector']);
            return false;
          }
          window.location.reload();
          this.router.navigate(['dashboard/administrator']);
          return false;
        }
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
  }
}
